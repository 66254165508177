import { BookedJobs } from './components/BookedJobs';
import { ActiveJob } from './components/ActiveJob';
import { FinalizeJob } from './components/FinalizeJob';
import { ConfirmJob } from './components/ConfirmJob';
import { AcceptJob } from './components/AcceptJob';
import { DeclineJob } from './components/DeclineJob';

const routes = [
  {
    path: '/jobs',
    name: 'Booked Jobs',
    component: BookedJobs,
    children: [{
      path: '/:active(\\d+)',
      name: 'Active Job',
      component: ActiveJob,
      exact: true,
    },{
      path: '/:active(\\d+)/finalize',
      name: 'Finalize Job',
      component: FinalizeJob,
      exact: true,
    }],
  },
  {
    path: '/confirm-job/:token',
    name: 'Confirm Job',
    component: ConfirmJob,
    allowGuest: true,
    children:[
      {
        path: '/:action(accept)',
        name: 'Accept Job',
        component: AcceptJob,
        exact: true,
        allowGuest: true,
      },
      {
        path: '/:action(decline)',
        name: 'Decline Job',
        component: DeclineJob,
        exact: true,
        allowGuest: true,
      },
    ]
  },
];

export default routes;
