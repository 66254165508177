import { generate, request } from '@moved/services';
import { get } from 'lodash';

export const {
  action: finalizeJob,
  reducer,
  usePending: useFinalizeJobPending,
} = generate.redux({
  namespace: 'JOBS_FINALIZE_JOB',
  request: (job, data={}) => request.post(`/vendors/jobs/${job.id}/finalize`, data).then(r => r.data.data.vendor_job),
  reducers: {
    success: (state, { response: job }) => {
      // Find and update the status if this job is also in the active results list
      let activeSet = get(state,'jobs.activeSet',[]);
      activeSet = activeSet.map(item => {
        if(item.id === job.id) return {
          ...item,
          status: job.status,
          total: job.total,
          final_price: job.final_price,
          revenue: job.revenue,
        }
        else return item;
      });

      return {
        ...state,
        jobs: {
          ...state.jobs,
          activeSet: activeSet,
        },
        jobDetails: {
          ...state.jobDetails,
          [job.id]: job,
        },
      }
    },
  },
});
