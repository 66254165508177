import React, { useState } from 'react';

import { useScroller } from '@moved/services';

import { NavHeader } from './NavHeader';
import { AppNavigation } from './AppNavigation';
import { StripeBanner } from './StripeBanner';

import layoutCSS from '../styles/Layout.module.scss';

export const LayoutStandard = ({ leftHeaderContent, rightHeaderContent, children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // define this as the scroll parent until unmounted
  const scroller = useScroller();

  return (
    <div className={layoutCSS.template}>
      <NavHeader
        menuAction={toggleMenu}
        menuOpen={menuOpen}
        leftContent={leftHeaderContent}
        rightContent={rightHeaderContent}
      />
      <AppNavigation open={menuOpen} />
      <StripeBanner />
      <section ref={scroller.ref} className={layoutCSS.content}>
        {children}
      </section>

    </div>
  );
};
