import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, useUser, RouteTree, format } from '@moved/services';
import { ProxyModeBadge } from '@moved/product';
import { LoaderOverlay } from '@moved/ui';

import { getVendor } from '../../features/common/actions';
import { useVendor, useVendorPending } from '../../features/common/actions/selectors';

import CSS from '../styles/App.module.scss';

export const App = () => {
  const { user } = useUser();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const vendor = useVendor();
  const pending = useVendorPending();

  // Fetch vendor data on load
  useEffect(() => {
    if(!user?.vendor_id) return;
    dispatch(getVendor(user.vendor_id))
      .catch(err => notify.error(format.error(err)));
  },[user, notify, dispatch]);

  if(pending || (user?.vendor_id && !vendor)) return <LoaderOverlay/>;
  return (
    <div className={CSS.vendor_app}>
      <RouteTree />
      <ProxyModeBadge/>
    </div>
  );
};
