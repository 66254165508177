import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'AVAILABILITY_SAVE_SLOT',
  request: (data={}) => request.post(`/vendors/calendar/update`, data).then(r => r.data),
  reducers: {
    success: (state, { response }) => {
      const date = Object.keys(response)[0];
      const newSlot = response[date].slots[0];
      const updatedSlots = state.availability.schedules[date].slots.map((slot, index) => {
        if(slot.position === newSlot.position) return newSlot;
        return slot;
      });
      return {
        ...state,
        availability: {
          ...state.availability,
          schedules: {
            ...state.availability.schedules,
            [date]: {
              ...state.availability.schedules[date],
              slots: updatedSlots,
            }
          }
        }
      };
    },
  }
});

export const initialState = {};
