import React from 'react';
import { Providers, generate } from '@moved/services';

import '../styles/Root.scss';

import '../defaults';
import { routeTree } from '../routes';
import { App } from './App';
import { reducer } from '../reducers';

const store = generate.reduxStore(reducer);

export const Root = () => (
  <Providers
    store={store}
    routeTree={routeTree}
    defaultDomains={['vendor']}
    exclude={['Stripe','Track']}
  >
    <App />
  </Providers>
);
